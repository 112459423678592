import React, { useState, useEffect, useRef } from "react";
import "./engagementInput.scss";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const EngagementSearchInput = ({ value = "", onChange = () => {}, leads }) => {
    const [suggestions, setSuggestions] = useState([]);
    const containerRef = useRef(null); // Ref for the container

    // Update suggestions when the search term or leads change
    useEffect(() => {
        if (value.trim() === "") {
            setSuggestions([]);
            return;
        }

        // Collect matching words from engagements
        const matchedWords = new Set();
        leads.forEach((lead) => {
            if (lead.engagements) {
                lead.engagements.forEach((engagement) => {
                    const words = engagement.message.split(/\s+/); // Split message into words
                    words.forEach((word) => {
                        if (word.toLowerCase().includes(value.toLowerCase())) {
                            matchedWords.add(word);
                        }
                    });
                });
            }
        });

        setSuggestions([...matchedWords].slice(0, 10)); // Limit to 10 suggestions
    }, [value, leads]);

    // Handle clicks outside the component to hide suggestions
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                setSuggestions([]); // Hide suggestions
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    // Handle click on a suggestion
    const handleSuggestionClick = (suggestion) => {
        onChange(suggestion);
        setSuggestions([]);
    };

    return (
        <div className="input-with-icon" ref={containerRef}>
            <FontAwesomeIcon icon={faSearch} className="icon" />
            <input
                type="text"
                placeholder="Search..."
                value={value}
                onChange={(e) => onChange(e.target.value)}
                className="input"
                onFocus={() => {
                    if (value.trim() !== "") {
                        setSuggestions((prevSuggestions) => prevSuggestions); // Show suggestions again
                    }
                }}
            />
            {suggestions?.length > 1 && (
                <ul className="suggestions-dropdown">
                    {suggestions?.map((suggestion, index) => (
                        <li
                            key={index}
                            onClick={() => handleSuggestionClick(suggestion)} // Handle click
                            className="suggestion-item"
                        >
                            <FontAwesomeIcon icon={faSearch} />
                            <span>{suggestion}</span>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default EngagementSearchInput;










// travel agencies, air line, hotel, anyone








// // working with adding search
// import React, { useState, useEffect } from "react";
// import "./engagementInput.scss";
// import { faSearch } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// const EngagementSearchInput = ({ value = "", onChange = () => {}, leads }) => {
//     const [suggestions, setSuggestions] = useState([]);

//     // Update suggestions when the search term or leads change
//     useEffect(() => {
//         if (value.trim() === "") {
//             setSuggestions([]);
//             return;
//         }

//         // Collect matching words from engagements
//         const matchedWords = new Set();
//         leads.forEach((lead) => {
//             if (lead.engagements) {
//                 lead.engagements.forEach((engagement) => {
//                     const words = engagement.message.split(/\s+/); // Split message into words
//                     words.forEach((word) => {
//                         if (word.toLowerCase().includes(value.toLowerCase())) {
//                             matchedWords.add(word);
//                         }
//                     });
//                 });
//             }
//         });

//         setSuggestions([...matchedWords].slice(0, 10)); // Limit to 10 suggestions
//     }, [value, leads]);

//     return (
//         <div className="input-with-icon">
//             <FontAwesomeIcon icon={faSearch} className="icon" />
//             <input
//                 type="text"
//                 placeholder="Search..."
//                 value={value}
//                 onChange={(e) => onChange(e.target.value)}
//                 className="input"
//             />
//             {suggestions.length > 0 && (
//                 <ul className="suggestions-dropdown">
//                     {suggestions.map((suggestion, index) => (
//                         <li
//                             key={index}
//                             onClick={() => onChange(suggestion)} // Populate search bar on click
//                             className="suggestion-item"
//                         >
//                             <FontAwesomeIcon icon={faSearch} />
//                             <span>
//                                 {suggestion}
//                             </span>
//                         </li>
//                     ))}
//                 </ul>
//             )}
//         </div>
//     );
// };

// export default EngagementSearchInput;















// // working not adding search
// import React from 'react';
// import './engagementInput.scss';
// import { faSearch } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// const EngagementSearchInput = ({ value = "", onChange = () => {} }) => {
//     return (
//         <div className="input-with-icon">
//             <FontAwesomeIcon icon={faSearch} className="icon" />
//             <input
//                 type="text"
//                 placeholder="Search..."
//                 value={value}
//                 onChange={(e) => onChange(e.target.value)}
//                 className="input"
//             />
//         </div>
//     );
// };

// export default EngagementSearchInput;
