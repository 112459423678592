import React, { Component } from 'react';
import { db, auth } from '../../../utils/firebase';
import EditLead from '../../forms/edit-lead';

/**
 * Components
 */
import Engagements from '../../engagements/engagements';
import Actions from '../../actions/actions';

/**
 * Modal wrapper
 */
import Modal from '../modal';

class ViewLead extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active_tab: 'details',
            client: '',
            lead: '',
            staff: false
        };
    }

    /**
     * Component mount
     *
     * @function
     */
    componentDidMount() {
        this._isMounted = true;
    }

    /**
     * Component update
     *
     * @function
     */
    componentDidUpdate() {
        /**
         * Get the lead ID from props
         */
        const { lead } = this.props;
        /**
         * If it's different to what we've already got
         */
        if (lead && (lead.id !== this.state.lead.id)) {
            /**
             * Update the state with the lead data
             */
            this.setState({ lead }, () => {
                /**
                 * Then fetch an up to date copy from the database
                 */
                this.fetchLead();
                /**
                 * Check the users permissions for remove/edit
                 */
                this.checkUserStatus();
                /**
                 * Set the state to point stright to details
                 */
                this.setState({ active_tab: 'details' });
            });
        }
    }

    /**
     * Component unmount
     *
     * @function
     */
    componentWillUnmount() {
        this._isMounted = false;
        this.state.lead = '';
    }

    /**
     * Fetch the lead information for updating and populating fields
     *
     * @function
     */
    fetchLead = () => {
        /**
         * Deconstruct the lead data from props
         */
        const { client, lead } = this.props;
        /**
         * Fetch the data from the database
         */
        db.doc(`clients/${client}/leads/${lead}`).get().then((lead) => {
            /**
             * If the lead data exists
             */
            if (lead.exists) {
                /**
                 * Update the state with the data
                 */
                this._isMounted && this.setState({ lead: { id: lead.id, ...lead.data() } });
            }
        });
    }

    /**
     * Update the state with a lowercase string version of the tab required
     * to switch to it on the UI
     *
     * @function
     *
     * @param {String} tab String value of the tab to switch to
     */
    navigateTab = (tab) => {
        /**
         * Lowercase the tab name and set the state
         */
        this.setState({ active_tab: tab.toLowerCase() });
    }

    /**
     * Check the status of a user for being a staff member
     *
     * @function
     */
    checkUserStatus = () => {
        /**
         * Get the users database record
         */
        db.doc(`users/${auth.currentUser.uid}`).get().then((user) => {
            /**
             * If the users document exists
             */
            if (user.exists) {
                /**
                 * Update the state to whether or not they have staff rights
                 */
                this.setState({
                    ...this.state,
                    staff: user.data().type === 'staff'
                });
            }
        });
    }

    dismiss = () => {
        this.setState({ lead: '' });
        this.props.dismiss();
    };

    render() {
        return (
            <Modal active={this.props.active} toggle={this.dismiss}>
                <h1>Lead Details</h1>

                {/* Modal navigation */}
                <div className="modal-nav">
                    <p className={this.state.active_tab === 'details' ? 'active' : ''}
                        onClick={() => this.navigateTab('details')}>
                        Details
                    </p>
                    <p className={this.state.active_tab === 'engagement' ? 'active' : ''}
                        onClick={() => this.navigateTab('engagement')}>
                        Engagement
                    </p>
                    <p className={this.state.active_tab === 'actions' ? 'active' : ''}
                        onClick={() => this.navigateTab('actions')}>
                        Actions
                    </p>
                </div>

                {/* Edit lead form */}
                {this.state.active_tab === 'details' &&
                    <EditLead
                        active={this.state.active_tab === 'details'}
                        client={this.props.client}
                        lead={this.props.lead}
                        staff={this.state.staff}
                        users={this.props?.users}
                        dismiss={this.props.dismiss} />}

                {/* Lead Engagements */}
                {this.state.active_tab === 'engagement' &&
                    <Engagements
                        active={this.state.active_tab === 'engagement'}
                        client={this.props.client}
                        lead={this.state.lead.id}
                        staff={this.state.staff} />}

                {/* Lead Actions */}
                {this.state.active_tab === 'actions' &&
                    <Actions
                        active={this.state.active_tab === 'actions'}
                        client={this.props.client}
                        lead={this.state.lead.id}
                        staff={this.state.staff} />}
            </Modal>
        );
    }
}

export default ViewLead;