import React, { useEffect, useState } from "react";
import { db } from "../../utils/firebase";
// import moment from "moment";
import "./sdr_dashboard.scss";
import firebase from "firebase";

/**
 * UI components
 */
import Staff from "./staff/staff";
import Select from "../../components/ui/selects/select";
import { LoadingIconNew } from "../../utils/svgs";

/**
 * Functional component to return the appointments leaderboard
 */
export default function SdrDashboard() {
    const moment = require('moment');
    moment.locale('en-au')
    const [clients, setClients] = useState({});
    // const [sortedClients, setSortedClients] = useState([]);
    const [monthName, setMonthName] = useState(moment().format("MMMM"));
    const [monthScope, setMonthScope] = useState(moment().format("MM"));
    const [yearScope, setYearScope] = useState("By Monetary Value");
    const [staffList, setStaffList] = useState([])
    const [staffLeadsReacord, setStaffLeadsReacord] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [activeStaffIds, setActiveStaffIds] = useState([]);
    const [prevYears, setPrevYears] = useState([]);
    const [selectedYear, setSelectedYear] = useState(moment().year());
    // const [sortedStaffLeadsRec, setsortedStaffLeadsRec] = useState({});
    // const [staffLeadsReacSortByRev, setStaffLeadsReacSortByRev] = useState([]);
    // const [yearList, setyearList] = useState([])
    // console.log(yearScope, "==asjcssac:yearScope");
    let defaultAppointment = 50;
    let defaultRevenue = 15000;

    // useEffect(() => {
    //     const monthNumber = moment(monthName, "MMMM").format("MM");
    //     setMonthScope(monthNumber);
    // }, [monthName]);


    // const fetchStaffList = async () => {
    //     try {
    //         const staffDocs = await db.collection('users').where('type', '==', 'staff').get();

    //         let staffArr = [];
    //         staffDocs.forEach(staff => {
    //             staffArr.push({ id: staff.id, ...staff.data()});
    //         });

    //         setStaffList(staffArr);
    //         console.log(staffArr, 'final9090999===staffDocsstaffDocs:2');
    //     } catch (error) {
    //         console.error("Error fetching staff list:", error);
    //     }
    // };


    // const queryss = async (client, savedBy) => {
    //     console.log(client, " +===+ ", savedBy);

    //     let query = db.collection(`clients/${client}/leads`)
    //         .where("saved_by", "==", savedBy);
    //     // let isFilterFromTill = false;

    //     // if (filterState.leadtype) {
    //     //     query = query.where("type", "==", filterState.leadtype)
    //     // }
    //     // if (filterState.from) {
    //     //     isFilterFromTill = true;
    //     //     let from = '', till = '';
    //     //     if (filterState.from) {
    //     //         isFilterFromTill = true;
    //     //         from = new Date(0);
    //     //         from.setUTCSeconds(filterState?.from?.seconds);
    //     //         // from.setTime(from.getTime() - 24 * 60 * 60 * 1000);
    //     //         from = firebase.firestore.Timestamp.fromDate(from);
    //     //         query = query.where("created", ">=", from);
    //     //         setMessage("no record found")

    //     //     }
    //     //     if (filterState.till) {

    //     //         isFilterFromTill = true;
    //     //         till = new Date(0);
    //     //         till.setUTCSeconds(filterState?.till?.seconds);
    //     //         till.setTime(till.getTime() + 24 * 60 * 60 * 1000);
    //     //         till = firebase.firestore.Timestamp.fromDate(till);
    //     //         query = query.where("created", "<=", till);
    //     //         setMessage("no record found")
    //     //     }
    //     // }
    //     // if (startTimestamp && !isFilterFromTill && filterState.name == "" && filterState.leadtype == "") {
    //     //     query = query.where("updated", ">=", startTimestamp)
    //     // }

    //     query.onSnapshot(querySnapshot => {
    //         console.log(querySnapshot.size, "SIZE");
    //         if (querySnapshot.size) {
    //             const leads = querySnapshot.docs.map(doc => ({
    //                 id: doc.id,
    //                 ...doc.data(),
    //             }));

    //             return leads;
    //             console.log(leads, "final9090999===leaddssssss");
    //         }
    //         else { 
    //             return [];
    //         }
    //     });
    // }

    const fetchAllClients = async () => {
        const updatedClients = {};
        db.collection("clients")
            // .where("archived", "==", false)
            // .orderBy("name")
            .onSnapshot((clientsSnap) => {
                // console.log("=aacmsakcsaacsac:clientssss:2");
                clientsSnap.docs.forEach((doc) => {
                    const data = {
                        id: doc.id,
                        ...doc.data(),
                    }
                    // console.log(data, "sacmkascaskcmasmsacasm:data");
                    updatedClients[doc.id] = data;
                });
                // console.log(updatedClients, "===updatedClientsupdatedClients");
                setClients(updatedClients);
                return updatedClients;
            });
    };


    // const fetchData = async () => {
    //     await fetchStaffList();
    //     await fetchAllClients();
    // }

    // const func = async () => {
    //     if(clients.length > 0 && staffList.length > 0){
    //         const firstStaff = staffList[0];
    //         const allProm = clients.map(client => queryss(client.id, firstStaff.id));
    //         const res = await Promise.all(allProm);
    //         console.log(res, "=ascancjasn:ressssss");
    //     }
    // }



    const fetchStaffList = async () => {
        try {
            const staffDocs = await db.collection('users')
                .where('type', '==', 'staff')
                .where("is_active", "==", true)
                .get();

            const allIds = [];
            const staffArr = staffDocs.docs.map((staff) => {
                allIds.push(staff.id);
                return {
                    id: staff.id,
                    ...staff.data(),
                }
            });

            setActiveStaffIds(allIds);
            setStaffList(staffArr);
            console.log(staffArr, 'final9090999===staffDocsstaffDocs:2');
        } catch (error) {
            console.error("Error fetching staff list:", error);
        }
    };


    // const fetchAllClients = async () => {
    //     console.log("=aacmsakcsaacsac:clientssss:1");

    //     const unsubscribeClients = db
    //         .collection("clients")
    //         .where("archived", "==", false)
    //         .onSnapshot(
    //             (clientsSnap) => {
    //                 console.log("=aacmsakcsaacsac:clientssss:2");
    //                 const updatedClients = clientsSnap.docs.map((doc) => ({
    //                     id: doc.id,
    //                     ...doc.data(),
    //                 }));

    //                 console.log(updatedClients, "=aacmsakcsaacsac:clientssss");
    //                 setClients(updatedClients);
    //             },
    //             (error) => {
    //                 console.error("Error fetching clients:", error);
    //             }
    //         );

    //     return unsubscribeClients;
    // };

    // const sortStaffDataByViewDropdown = (dataBeforeSort, type) => {
    //     type = type === "By Monetary Value" ? "totalRevenue" : "totalAppointments";

    //     if (!dataBeforeSort || typeof dataBeforeSort !== 'object') {
    //         console.error("Invalid data for sorting. Falling back to default.");
    //         dataBeforeSort = staffLeadsReacord; // Default to existing data if not valid
    //     }

    //     // Convert the object to an array for sorting
    //     const dataArray = Object.keys(dataBeforeSort).map((key) => ({
    //         id: key,
    //         ...dataBeforeSort[key],
    //     }));

    //     if (!dataArray.length || !(type in dataArray[0])) {
    //         console.error(`Invalid type '${type}' for sorting.`);
    //         setIsLoading(false);
    //         return;
    //     }

    //     // Sort the data based on the selected type (totalRevenue or totalAppointments)
    //     dataArray.sort((a, b) => (b[type] || 0) - (a[type] || 0));

    //     const data = [...dataArray];
    //     // Assign ranks
    //     let currentRank = 1;
    //     for (let i = 0; i < data.length; i++) {
    //         // debugger
    //         // Check if the current item is the same as the previous item (same value for the selected type)
    //         if (i > 0 && data[i][type] == data[i - 1][type]) {
    //             // If so, assign the same rank
    //             data[i].rank = currentRank;
    //         } else {
    //             // Otherwise, assign the next rank
    //             data[i].rank = currentRank;
    //             currentRank++;
    //         }
    //     }

    //     // Set the state with the updated sorted data
    //     console.log(data, "+==dataArraydataArray");

    //     setStaffLeadsReacord(dataArray);
    //     setIsLoading(false);
    // };

    const sortStaffDataByViewDropdown = (dataBeforeSort, type) => {
        type = type === "By Monetary Value" ? "totalRevenue" : "totalAppointments";

        // if (!dataBeforeSort || typeof dataBeforeSort !== 'object') {
        //     console.error("Invalid data for sorting. Falling back to default.");
        //     dataBeforeSort = staffLeadsReacord; // Default to existing data if not valid
        // }

        // Convert the object to an array for sorting
        const dataArray = [...dataBeforeSort]; // Directly use the dataBeforeSort array, no need for Object.keys

        // if (!dataArray.length || !(type in dataArray[0])) {
        //     console.error(`Invalid type '${type}' for sorting.`);
        //     setIsLoading(false);
        //     return;
        // }

        // Sort the data based on the selected type (totalRevenue or totalAppointments)
        dataArray.sort((a, b) => (b[type] || 0) - (a[type] || 0));

        const data = [...dataArray];

        console.log(dataBeforeSort, "==acjsajcsjandataBeforeSort", dataArray, " =scamcsak, ", data);
        // Assign ranks
        let currentRank = 1;
        for (let i = 0; i < data.length; i++) {
            // Check if the current item is the same as the previous item (same value for the selected type)
            if (i > 0 && data[i][type] === data[i - 1][type]) {
                // If so, assign the same rank
                data[i].rank = data[i - 1].rank;
            } else {
                // Otherwise, assign the next rank
                data[i].rank = currentRank;
                currentRank++;
            }
        }

        // Set the state with the updated sorted data
        console.log(data, "+==dataArraydataArray");

        setStaffLeadsReacord(data);
        setIsLoading(false);
    };



    // const sortStaffDataByViewDropdown = (dataBeforeSort, type) => {
    //     type = type === "By Monetary Value" ? "totalRevenue" : "totalAppointments";
    //     if (!dataBeforeSort || typeof dataBeforeSort !== 'object') {
    //         console.error("Invalid data for sorting. Falling back to default.");
    //         dataBeforeSort = staffLeadsReacord; // Default to existing data if not valid
    //     }

    //     // Convert the object to an array for sorting
    //     const dataArray = Object.keys(dataBeforeSort).map((key) => ({
    //         id: key,
    //         ...dataBeforeSort[key],
    //     }));

    //     if (!dataArray.length || !(type in dataArray[0])) {
    //         console.error(`Invalid type '${type}' for sorting.`);
    //         setIsLoading(false);
    //         return;
    //     }

    //     dataArray.sort((a, b) => (b[type] || 0) - (a[type] || 0));
    //     setStaffLeadsReacord(dataArray);
    //     setIsLoading(false);
    // };


    const getAllLeads = async (monthName, selectedYear) => {
        // const client = await fetchAllClients();
        // console.log(clients, "===clientclientclientaaa", activeStaffIds);
        // console.log(selectedYear, "=ascassacnsncselectedYear");


        if (Object.keys(clients).length === 0) return;

        let finalData = {};
        const currentYear = moment().year();
        // const startOfMonth = moment(monthName, "MMMM").startOf("month").utc().toDate();
        // const endOfMonth = moment(monthName, "MMMM").endOf("month").utc().endOf('day').toDate();
        const startOfMonth = moment(monthName, "MMMM").startOf("month").year(selectedYear).utc().toDate();
        const endOfMonth = moment(monthName, "MMMM").endOf("month").year(selectedYear).utc().endOf('day').toDate();

        const startTimestamp = firebase.firestore.Timestamp.fromDate(startOfMonth);
        const endTimestamp = firebase.firestore.Timestamp.fromDate(endOfMonth);

        // console.log(startOfMonth, " [=== asmcacacna", endOfMonth);

        // console.log(startTimestamp, " ===anastartTimestamp, ", endTimestamp, ", == ", monthName);

        // Apply filtering using where
        const leadsRef = firebase.firestore()
            .collectionGroup('leads')
            .where("type", "==", "9_Appointment")
            .where("updated", ">=", startTimestamp)
            .where("updated", "<=", endTimestamp);

        // Set up a real-time listener
        leadsRef.onSnapshot((snapshot) => {
            finalData = {}; // Reset finalData for every update
            const d = {};
            // console.log("Running 1");
            // console.log(snapshot, ' = Running');


            snapshot.forEach((doc) => {
                // console.log("Running 2");
                let data = { id: doc.id, ...doc.data(), };
                // console.log("Running 3", data);
                const userId = data.lead_assigned_to || data.saved_by;
                // console.log(userId, "====userIddsssssss");
                const clientId = doc.ref.path.split('/')[1];
                const client = clients[clientId];
                // console.log(clients, " , ===cassaanascnasjnsClientt, ", userId, "  ==, ", clientId);

                if (userId?.length > 0 && client) {
                    if (d[userId]) { d[userId].push(data) }
                    else { d[userId] = [data] };
                    if (!finalData[userId]) {
                        finalData[userId] = { appointment: {}, monetary: {}, totalAppointments: 0, totalRevenue: 0, };
                    }
                    const revenue = !isNaN(Number(client.pound_value)) ? Number(client.pound_value) : 0;
                    finalData[userId]["totalAppointments"] = (finalData[userId]["totalAppointments"] || 0) + 1;
                    finalData[userId]["totalRevenue"] = (finalData[userId]["totalRevenue"] || 0) + revenue;
                    finalData[userId]["appointment"][data?.where] = (finalData[userId]["appointment"][data?.where] || 0) + 1;
                    if (revenue > 0) {
                        finalData[userId]["monetary"][data?.where] = (finalData[userId]["monetary"][data?.where] || 0) + revenue;
                    }
                }
            });

            // console.log(finalData, 'Real-time updated datancajsaj', activeStaffIds);
            // console.log(d, 'Real-time updated data');

            let finalDataArr = [];
            Object.keys(finalData).forEach(staffId => {
                if (activeStaffIds.includes(staffId)) {
                    const progressByRev = finalData[staffId]["totalRevenue"] / defaultRevenue * 100;
                    const progressByApmnt = finalData[staffId]["totalAppointments"] / defaultAppointment * 100;
                    finalDataArr.push({ id: staffId, ...finalData[staffId], progressByRev, progressByApmnt, });
                }
            });

            // Call your sorting and update logic
            sortStaffDataByViewDropdown(finalDataArr, yearScope);
        }, (error) => {
            console.error("Error setting up real-time listener: ", error);
        });
    };


    // const getAllLeads = (monthName) => {
    //     let fianlData = {};
    //     // const fianlData2 = [];
    //     const currentYear = moment().year();
    //     const startOfMonth = moment(monthName, "MMMM").startOf("month").year(currentYear).toDate();
    //     const endOfMonth = moment(monthName, "MMMM").endOf("month").year(currentYear).toDate();

    //     const startTimestamp = firebase.firestore.Timestamp.fromDate(startOfMonth);
    //     const endTimestamp = firebase.firestore.Timestamp.fromDate(endOfMonth);

    //     // Apply filtering using where
    //     const leadsRef = firebase.firestore()
    //         .collectionGroup('leads')
    //         .where("type", "==", "9_Appointment")
    //         .where("created", ">=", startTimestamp)
    //         .where("created", "<=", endTimestamp);

    //     leadsRef.get()
    //         .then((snapshot) => {
    //             snapshot.forEach((doc) => {
    //                 let data = { id: doc.id, ...doc.data() };
    //                 if (!fianlData[data.lead_assigned_to]) {
    //                     fianlData[data.lead_assigned_to] = { appointment: {}, monetary: {}, totalAppointments: 0, totalRevenue: 0, };
    //                 }
    //                 const revenue = !isNaN(Number(data.pound_value)) ? Number(data.pound_value) : 0;
    //                 fianlData[data.lead_assigned_to]["totalAppointments"] = (fianlData[data.lead_assigned_to]["totalAppointments"] || 0) + 1;
    //                 fianlData[data.lead_assigned_to]["totalRevenue"] = (fianlData[data.lead_assigned_to]["totalRevenue"] || 0) + revenue;
    //                 fianlData[data.lead_assigned_to]["appointment"][data?.where] = (fianlData[data.lead_assigned_to]["appointment"][data?.where] || 0) + 1;
    //                 if (revenue > 0) {
    //                     fianlData[data.lead_assigned_to]["monetary"][data?.where] = (fianlData[data.lead_assigned_to]["monetary"][data?.where] || 0) + revenue;
    //                 }
    //             });

    //             console.log(fianlData, '=====acanjanjcjncsajcsajns!!11');

    //             let fianlDataArr = Object.keys(fianlData).map(staffId => {
    //                 const progressByRev = fianlData[staffId]["totalRevenue"] / defaultRevenue * 100;
    //                 const progressByApmnt = fianlData[staffId]["totalAppointments"] / defaultAppointment * 100;
    //                 return { id: staffId, ...fianlData[staffId], progressByRev, progressByApmnt, };
    //             });
    //             // let fianlDataObj = {};
    //             // fianlDataArr.forEach((data) => {
    //             //     fianlDataObj[data.id] = data;
    //             // });

    //             // setStaffLeadsReacord(fianlDataObj);
    //             sortStaffDataByViewDropdown(fianlDataArr, yearScope);
    //         })
    //         .catch((error) => {
    //             console.error("Error retrieving leads: ", error);
    //         });
    // };

    const fetchData = async () => {
        // setIsLoading(true);
        await fetchAllClients();
        await fetchStaffList();
    };

    function years(count, startYear) {
        const yearList = [];
        const year = startYear || new Date().getFullYear();
        for (let i = 0; i < count; i += 1) {
            yearList.push(Number(year) - i)
        }
        yearList.sort((a, b) => a - b)
        // return yearList.reverse()
        setPrevYears(yearList.reverse())
    }

    useEffect(() => {
        if (Object.keys(staffLeadsReacord).length > 0) {
            setIsLoading(true);
            sortStaffDataByViewDropdown(staffLeadsReacord, yearScope);
        }
    }, [yearScope])

    useEffect(() => {
        const fetchDataAndSetup = async () => {
            const unsubscribeClients = await fetchData();

            return () => {
                if (typeof unsubscribeClients === "function") {
                    unsubscribeClients();
                }
            };
        };

        fetchDataAndSetup();
        years(100, moment().year());
    }, []);

    useEffect(() => {
        setIsLoading(true);
        if (activeStaffIds?.length > 0 && Object.keys(clients || {}).length > 0) {
            getAllLeads(monthName, selectedYear);
        }
    }, [monthName, clients, activeStaffIds, selectedYear])


    return (
        <div id="appointments-window">
            <div className="appointments-title">
                <h6>SDR</h6>

                <Select
                    placeholder="Choose month:"
                    options={{
                        "1": "January",
                        "2": "February",
                        "3": "March",
                        "4": "April",
                        "5": "May",
                        "6": "June",
                        "7": "July",
                        "8": "August",
                        "9": "September",
                        "10": "October",
                        "11": "November",
                        "12": "December",
                    }}
                    value={monthName}
                    onSelect={(option) => setMonthName(option.value)} />

                <Select
                    placeholder="Choose year:"
                    options={prevYears.map((val) => val)}
                    value={selectedYear}
                    onSelect={(option) => setSelectedYear(option.value)}
                />
                <Select
                    placeholder="Select Type"
                    options={{
                        "1": "By Monetary Value",
                        "2": "By Total Appointments",
                    }}
                    // options={prevYears.map((val) => val)}
                    value={yearScope}
                    onSelect={(option) => setYearScope(option.value)} //
                />
            </div>

            {isLoading ? <>
                <LoadingIconNew />
            </> : (staffLeadsReacord?.length > 0 && staffList.length > 0) ? (
                <>
                    {staffLeadsReacord?.map((data, i) => {
                        const staffData = staffList?.find((record) => record.id === data.id);
                        { console.log(staffData, " , =acnacnjasncjasnjcasajncs, ", data) }
                        if (!staffData) return null;

                        return (
                            <Staff
                                key={data.id}
                                staff={staffData}
                                data={data}
                                type={yearScope}
                                // index={data.rank}
                                defaultAppointment={defaultAppointment}
                                defaultRevenue={defaultRevenue}
                            />
                        );
                    })}
                </>
            ) : (
                <div className="not_assign_leads_title">
                    You have not assigned any leads to any user
                </div>
            )}
        </div>
    );
}