import React, { Component } from 'react';
import firebase from 'firebase';
import { db, auth } from '../../utils/firebase';
import { lead_types, lead_origin } from '../../utils/selects';
// import moment from 'moment';
import 'moment/locale/de';
/**
 * Datetime picker
 */
import Datetime from 'react-datetime';

/**
 * Components
 */
import Input from '../ui/input/input';
import TextArea from '../ui/input/textarea';
import Select from '../ui/input/select';
import Button from '../ui/button/button';
import { connect } from 'react-redux';

/**
 * Moment libary
 */
const moment = require('moment');

class AddLead extends Component {
    constructor(props) {
        super(props);
        this.state = {
            locale: "en-US",
            name: '',
            errors: {},
            job_title: '',
            company: '',
            website: '',
            contact_number: '',
            contact_email: '',
            linkedin: '',
            selecting_assign_to: false,
            lead_assigned_to_name: '',
            lead_assigned_to: '',
            type: '',
            where: '',
            // pound_value: '',
            init_message: '',
            init_engagement: Datetime.moment(),
            disabled:false,
        };
    }


    componentDidUpdate(prevProps, prevState) {
        if (prevProps.active !== this.props.active) {
            // Handle changes to `active` prop
            // console.log('The "active" prop has changed');
            this.handleDateChange(new Date());
        }
        // If the modal has been closed
        if (this.props.active === false && this.state.lead_assigned_to) {
            // Reset the state
            this.setState({
                lead_assigned_to: '',
                lead_assigned_to_name: '',
                selecting_assign_to: false, // Close the dropdown
            });
            // this.handleDateChange(new Date());
        }
    }
    
    /**
     * Component mount
     *
     * @function
     */
    componentDidMount() {
        document.addEventListener('mousedown', this.clickedOutsideWhereSelect);
        document.addEventListener('mousedown', this.clickedOutsideTypeSelect);
        document.addEventListener('mousedown', this.clickedOutsideAssignSelect);
        this.handleDateChange(new Date());
    }

    /**
     * Component mount
     *
     * @function
     */
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.clickedOutsideWhereSelect);
        document.removeEventListener('mousedown', this.clickedOutsideTypeSelect);
        document.removeEventListener('mousedown', this.clickedOutsideAssignSelect);
    }

    /**
     * Save new lead
     *
     * @function
     */
    saveLead = async () => {
        this.setState({disabled:true})

        /**
         * Get the client ID from props
         */
        const { client } = this.props;
        /**
         * Get the lead data from the state
         */
        const {

            name,
            errors: { },
            job_title,
            company,
            website,
            contact_number,
            contact_email,
            lead_assigned_to,
            linkedin,
            type,
            where,
            pound_value,
            init_message,
            init_engagement,
        } = this.state;
        /**
         * Add the lead
         */

        console.log(type.substring(2), "==testtststtststststst");
        

        const errors = this.checkInputs();

        const user = this.props?.users?.find(u => u.id == auth.currentUser?.uid);
        const dataToBeSend = {
            name,
            job_title,
            company,
            website,
            contact_number,
            contact_email,
            linkedin,
            type,
            where,
            init_message,
            lead_assigned_to: lead_assigned_to || auth?.currentUser?.uid,
            // init_engagement: Datetime.moment(),
            init_engagement: '',
            created: firebase.firestore.FieldValue.serverTimestamp(),
            updated: firebase.firestore.FieldValue.serverTimestamp(),
            saved_by: auth?.currentUser?.uid
        }

        console.log(dataToBeSend, "==acasjcsacsasjscjnasnsj");
        

        if(pound_value) dataToBeSend["pound_value"] = pound_value;

        if (!errors) {
            const lead_id = await db.collection(`clients/${client}/leads`).add(dataToBeSend).then(lead => { return lead.id });
            /**
             * Setup the initial message as the first enagement
             */
            await db.collection(`clients/${client}/leads/${lead_id}/engagements`).add({
                title: 'Initial Response',
                message: init_message,
                created: init_engagement,
                recorded_by: auth.currentUser.uid
            });
            /**
             * Reset the state
             */
            this.setState({
                name: '',
                errors: {},
                job_title: '',
                company: '',
                website: '',
                contact_number: '',
                contact_email: '',
                linkedin: '',
                type: '',
                where: '',
                // pound_value: '',
                lead_assigned_to: '',
                lead_assigned_to_name: '',
                init_message: '',
                init_engagement: Datetime.moment(),
                // init_engagement: {
                //     seconds: '',
                //     readable: ''
                // }
            }, () => {
                /**
                 * Hide the form
                 */
                this.props.dismiss();
                // this.handleDateChange(new Date());
            });
        }
        this.setState({disabled:false})
    }



    checkInputs = () => {
        /**
         * Get the engagement data from the state
         */
        const { name,
            job_title,
            company,
            website,
            contact_number,
            contact_email,
            linkedin,
            type,
            where,
            init_message,
            init_engagement } = this.state;
        /**
         * If no title was set
         */

        /**
         * Was the date recorded
         */
        if (!init_engagement || !init_engagement.readable) {
            this.setState({ errors: { created: 'Please select a recorded date/time' } });
            return true;
        }
    }

    /**
     * Wrapper refs for the lead origin select field
     */
    setWhereRef = node => {
        this.whereRef = node;
    }

    clickedOutsideWhereSelect = (e) => {
        if (this.whereRef && !this.whereRef.contains(e.target)) {
            this.setState({
                ...this.state,
                selecting_where: false
            });
        }
    }

    toggleWhereSelect = () => {
        this.setState({
            selecting_where: !this.state.selecting_where
        });
    }

    setAssignRef = node => {
        this.assignRef = node;
    }

    clickedOutsideAssignSelect = (e) => {
        if (this.assignRef && !this.assignRef.contains(e.target)) {
            this.setState({
                ...this.state,
                selecting_assign_to: false
            });
        }
    }

    toggleAssignToSelect = () => {
        this.setState((prevState) => ({
            selecting_assign_to: !prevState.selecting_assign_to,
        }));
    };
    
    assignToChanged = (value) => {
        console.log(value, "====valuevaluevalue");
        
        this.setState({
            lead_assigned_to: value.value,
            lead_assigned_to_name: value.option,
            selecting_assign_to: false,
        });
    };

    /**
     * Wrapper refs for the type select field
     */
    setTypeRef = node => {
        this.typeRef = node;
    }

    clickedOutsideTypeSelect = (e) => {
        if (this.typeRef && !this.typeRef.contains(e.target)) {
            this.setState({
                ...this.state,
                selecting_type: false
            });
        }
    }

    toggleTypeSelect = () => {
        this.setState({
            selecting_type: !this.state.selecting_type
        });
    }

    /**
     * Handle the event of an input change
     *
     * @function
     *
     * @param {Object} e Event object
     */
    handleInputChange = (e) => {
        /**
         * Update the state with the field
         */
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        });
    }

    /**
     * Update the state with a formatted verison of the datetime picker result
     *
     * @function
     *
     * @param {Object} date Date object from the datetime picker
     */
    handleDateChange = (date) => {
        /**
         * Parse the date into different string types
         */
        const readable = moment(date).format('DD/MM/YYYY HH:mm');
        const seconds = moment(date).format('X');
        /**
         * Then set the state with them
         */
        this.setState({
            ...this.state,
            init_engagement: {
                seconds: seconds,
                readable: readable
            }
        });
    }

    /**
     * Handle the change of the lead type
     *
     * @function
     *
     * @param {Object} value Object containing the readable and variable for the selected item
     */
    leadTypeChanged = (value) => {
        console.log(value, "===scancLeadValleuueeueu");
        
        const data = {
            ...this.state,
            type: value.value,
            selecting_type: false,
        }

        if(value.option === 'Appointment' || value.value === '9_Appointment'){
            data["pound_value"] = this.props.clientPoundValue;
        }
        else {
            if(data["pound_value"]) delete data["pound_value"];
        }

        this.setState(data);
    }

    /**
     * Handle the change of the lead where
     *
     * @function
     *
     * @param {Object} value Object containing the readable and variable for the selected item
     */
    leadWhereChanged = (value) => {
        this.setState({
            ...this.state,
            where: value.option,
            selecting_where: false
        });
    }

    render() {
        // console.log(this.props.clientPoundValue, "===clientPoundValueclientPoundValue");
        
        const userOption = this.props?.users?.reduce((acc, user) => {
            acc[user.name] = user.id;
            return acc;
        }, {});
        return (
            <div className={['modal-form show'].join(' ')}>
                <Input
                    id="name"
                    placeholder="Person name"
                    value={this.state.name}
                    onChange={this.handleInputChange} />
                <Input
                    id="job_title"
                    placeholder="Job title"
                    value={this.state.job_title}
                    onChange={this.handleInputChange} />
                <Input
                    id="company"
                    placeholder="Company"
                    value={this.state.company}
                    onChange={this.handleInputChange} />
                <Input
                    id="website"
                    placeholder="Website"
                    value={this.state.website}
                    onChange={this.handleInputChange} />
                <Input
                    id="contact_number"
                    placeholder="Phone number"
                    value={this.state.contact_number}
                    onChange={this.handleInputChange} />
                <Input
                    id="contact_email"
                    placeholder="Email address"
                    value={this.state.contact_email}
                    onChange={this.handleInputChange} />
                <Input
                    id="linkedin"
                    placeholder="LinkedIn profile"
                    value={this.state.linkedin}
                    onChange={this.handleInputChange} />
                <div className="inputfield" ref={this.setTypeRef} >
                    <Select
                        placeholder="Lead type"
                        options={lead_types}
                        value={this.state.type && this.state.type && this.state.type.substring(2)}
                        changed={this.leadTypeChanged}
                        toggled={this.state.selecting_type}
                        toggle={this.toggleTypeSelect} />
                </div>
                <div className="inputfield" ref={this.setWhereRef} >
                    <Select
                        placeholder="Lead origin"
                        options={lead_origin}
                        value={this.state.where}
                        changed={this.leadWhereChanged}
                        toggled={this.state.selecting_where}
                        toggle={this.toggleWhereSelect} />
                </div>

                {/* <Input
                    id="pound_value"
                    placeholder="Pound Value"
                    value={this.state.pound_value}
                    type="number"
                    onChange={this.handleInputChange} /> */}
                
                <div className="inputfield" ref={this.setAssignRef}>
                    {this.props.user?.is_admin && 
                        <Select
                            placeholder="Lead Assign To"
                            options={userOption}
                            value={this.state.lead_assigned_to_name || this.props?.users?.find(u => u.id == auth.currentUser?.uid)?.name}
                            changed={this.assignToChanged}
                            toggled={this.state.selecting_assign_to}
                            toggle={this.toggleAssignToSelect}
                        />
                    }
                </div>

                {/* Initial engagement */}
                <TextArea
                    id="init_message"
                    placeholder="Initial Message"
                    value={this.state.init_message}
                    onChange={this.handleInputChange} />

                {this.props.active &&
                    <Input
                        id="init_engagement"
                        placeholder="Initial engagement date"
                        // readOnly={true}
                        value={this.state.init_engagement?.readable}
                        // onChange={this.handleInputChange} 
                    />
                }
                    {/* <Datetime
                        // value={this.state.init_engagement?.readable}
                        // utc={false}
                        // displayTimeZone={this.props.displayTimeZone}
                        locale="en"
                        timeFormat={true}
                        inputProps={{ placeholder: 'Initial engagement date' }}
                        onChange={date => this.handleDateChange(date)} /> */}
                {this.state.errors.created && <p className="input-error">{this.state.errors.created}</p>}
                <Button disabled={this.state.disabled}  onClick={this.saveLead}>Save Lead</Button>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
        users: state.user?.myUser,
    };
};

export default connect(mapStateToProps)(AddLead);