import React, { Component } from 'react';
import { db } from '../../../utils/firebase';
import firebase from "firebase";

/**
 * Components
 */
import EditClient from '../../forms/edit-client';
import Stakeholders from '../../stakeholders/stakeholders';
import Button from '../../ui/button/button';

/**
 * Modal wrapper
 */
import Modal from '../modal';

class ViewClient extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active_tab: 'details',
            client: {}
        };
    }

    /**
     * Component mount
     *
     * @function
     */
    componentDidMount() {
        this._isMounted = true;
    }

    /**
     * Component update
     *
     * @function
     */
    componentDidUpdate() {
        /**
         * Get the client ID from props
         */
        const { client } = this.props;

        console.log(client, "acacancjnsanasnna:cle");
        
        /**
         * If it's different to what we've already got
         */
        if (client && (client !== this.state.clientID)) {
            /**
             * Update the state with the client data
             */
            this.setState({ client }, () => {
                /**
                 * Then fetch an up to date copy from the database
                 */
                this.fetchClient();
                /**
                 * Set the state to point stright to details
                 */
                this.setState({ active_tab: 'details', clientID: client });
            });
        }
    }

    /**
     * Component unmount
     *
     * @function
     */
    componentWillUnmount() {
        this._isMounted = false;
    }

    /**
     * Fetch the lead information for updating and populating fields
     *
     * @function
     */
    fetchClient = () => {
        /**
         * Deconstruct the clientID from props
         */
        const { client } = this.props;
        /**
         * Fetch the data from the database
        */
        db.doc(`clients/${client}`).get().then((client) => {
            /**
             * If the client data exists
             */
            if (client.exists) {
                /**
                 * Update the state with the data
                 */
                console.log({ id: client.id, ...client.data() }, "asncncjansncasns");
                
                this._isMounted && this.setState({ client: { id: client.id, ...client.data() } });
            }
        });
    }

    /**
     * Update the state with a lowercase string version of the tab required
     * to switch to it on the UI
     *
     * @function
     *
     * @param {String} tab String value of the tab to switch to
     */
    navigateTab = (tab) => {
        /**
         * Lowercase the tab name and set the state
         */
        this.setState({ active_tab: tab.toLowerCase() });
    }

    viewForm = () => {
        const { client } = this.state;

        window.location = `/onboarding/${client.onboard_form}`;
    }

    generateOnboardingForm = async () => {
        const { clientID } = this.state;

        const onboardDoc = await db.collection("onboarding").add({
            client: clientID,
            created: firebase.firestore.FieldValue.serverTimestamp(),
        });

        await db.doc(`clients/${clientID}`).set({
            onboard_form: onboardDoc.id,
        }, { merge: true });

        this.copyToClipboard(onboardDoc.id);
    }

    /**
     * Copy a link to the users clipboard
     */
    copyToClipboard = (id) => {
        /**
         * Build the string to copy to clipboard
         */
        const clipboardLink = `https://www.crm.curral.london/onboarding/${id}`;
        /**
         * Internet Explorer fallback check
         */
        if (window.clipboardData && window.clipboardData.setData) {
            /**
             * Prevent textarea being shown while dialog is visible.
             */
            return window.clipboardData.setData("Text", clipboardLink);
        } else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
            /**
             * Create a new textarea, fill the content and style it to the back
             */
            let textarea = document.createElement("textarea");
            textarea.textContent = clipboardLink;
            textarea.style.position = "fixed";
            /**
             * Append it to the DOM and then select it
             */
            document.body.appendChild(textarea);
            textarea.select();
            /**
             * Attempt to copy the string to the clipboard
             */
            try {
                document.execCommand("copy");
                /**
                 * Show an alert to say it was successful
                 */
                return true;
            }
            /**
             * Catch any exceptions
             */
            catch (e) {
                /**
                 * Show an alert to say it was unsuccessful
                 */
                return true;
            }
            /**
             * Cleanup
             */
            finally {
                document.body.removeChild(textarea);
            }
        }
    }
    render() {
        // console.log("IN CLIENT VIEW")
        // console.log(this)
        return (
            <Modal active={this.props.active} toggle={this.props.dismiss}>
                <h1>{this.state.client.name}</h1>

                {/* Modal navigation */}
                <div className="modal-nav">
                    <p className={this.state.active_tab === 'details' ? 'active' : ''}
                        onClick={() => this.navigateTab('details')}>
                        Details
                    </p>
                    <p className={this.state.active_tab === 'stakeholders' ? 'active' : ''}
                        onClick={() => this.navigateTab('stakeholders')}>
                        Stakeholders
                    </p>
                    <p className={this.state.active_tab === 'onboarding' ? 'active' : ''}
                        onClick={() => this.navigateTab('onboarding')}>
                        Onboarding
                    </p>
                </div>

                {/* Edit client form */}
                {this.state.active_tab === 'details' &&
                    <EditClient
                        active={this.state.active_tab === 'details'}
                        client={this.state.client}
                        dismiss={this.props.dismiss} />}

                {/* Stakeholders */}
                {this.state.active_tab === 'stakeholders' &&
                    <Stakeholders
                        active={this.state.active_tab === 'stakeholders'}
                        client={this.state.client}
                        dismiss={this.props.dismiss} />}

                {/* Onboarding */}
                {this.state.active_tab === 'onboarding' &&
                    <>
                        {this.state.client?.onboard_form &&
                            <Button onClick={() => this.viewForm()}>View Onboarding Form</Button>
                        }

                        {!this.state.client?.onboard_form &&
                            <Button onClick={() => this.generateOnboardingForm()}>Generate &amp; Copy Onboarding Link</Button>
                        }
                    </>
                }
            </Modal>
        );
    }
}

export default ViewClient;