import React, { useEffect, useState } from "react";
import { auth, db } from "../../../utils/firebase";
import firebase from "firebase";
import moment from "moment";
import "./leads.scss";
// import './leadsA.css';

/**
 * UI components
 */
import Lead from "./lead/lead";
import Button from "../../../components/ui/button/button";
import ViewLead from "../../../components/modal/types/view-lead";
import ViewSDR from "../../../components/modal/types/view-sdr";
import ViewFilter from "../../../components/modal/types/view-filter";
import { action_types, lead_types } from "../../../utils/selects";
import Datetime from 'react-datetime';
import 'moment/locale/en-au';
import { CSVLink, CSVDownload } from "react-csv";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import * as actionTypes from '../../../store/actions';
import { useLayoutEffect } from "react";
import LeadsCount from "./LeadsCount/LeadsCount";
import GenerateCSV from "./csv/GenerateCSV";
import debounce from "lodash.debounce";
// import 'moment/locale/es'  // without this line it didn't work


/**
 * Functional component to return the leads for a given client
*/
function Leads(props) {
    const moment = require('moment');
    moment.locale('en-au')
    const [leads, setLeads] = useState([]);
    const [inOrderLeads, setInOrderLeads] = useState([]);
    const [sortedLeads, setSortedLeads] = useState([]);
    const [monthsBack, setMonthsBack] = useState(2);
    const [showingLead, setShowingLead] = useState(false);
    const [showingSDR, setShowingSDR] = useState(false);
    const [lead, setLead] = useState({});
    const [filterState, setFilterState] = useState({
        name: '',
        leadtype: '',
        from: '',
        till: '',
        lead_assigned_to: '',

    })
    const [message, setMessage] = useState(" ")
    const [leadsCommplete, setLeadsComplete] = useState(false)


    const users = useSelector(state => state?.user?.myUser)
    const allUsers = useSelector(state => state.user.allUsers)
    console.log(users, "USEERASD", allUsers, "askcncancasj")
    const dispatch = useDispatch()
    /**
     * Deconstruct the clientID from the props
     */
    const { client, clientName, engagementSearch, handleLeadStore, } = props;
    console.log(clientName, "=cascnsncasns:clientName, ", engagementSearch);
    const [debouncedEngagementSearch, setDebouncedEngagementSearch] = useState(engagementSearch);

    /**
     * Generate a timestamp from the first of the previous month to collect leads from
     */
    const startFrom = moment().startOf("month").subtract(monthsBack, "months").format("x");


    const reset = () => {
        if (filterState.name || filterState.lead_assigned_to || filterState.leadtype || filterState.from || filterState.till) {
            setFilterState({
                name: '',
                from: '',
                leadtype: '',
                till: '',
                lead_assigned_to: '',
            })
        }
        if (monthsBack > 2) {
            setMonthsBack(2)
        }
    }
    const dismiss = () => {
        setShowingLead(false)
    }
    /**
     * On component load
     */
    // useEffect(() => {
    //     setLeads([]);
    //     setMessage("Loading...")
    //     const startTimestamp = firebase.firestore.Timestamp.fromMillis(startFrom);
    //     const queryss = async () => {
    //         // console.log("RUNNING")
    //         let query = db.collection(`clients/${client}/leads`)
    //         let isFilterFromTill = false;
    //         // console.log(query,"RUNNING 1")

    //         if (filterState.name) {
    //             query = query.where("saved_by", "==", filterState.name)
    //             // console.log(query,"RUNNING 2")
    //         }
    //         // console.log(filterState,"RUNNING test")
    //         if (!filterState.name && filterState.lead_assigned_to) {
    //             query = query.where("saved_by", "==", filterState.lead_assigned_to);

    //             // console.log(query,"RUNNING 3")
    //         }
    //         if (filterState.name && filterState.lead_assigned_to && filterState.name !== filterState.lead_assigned_to) {
    //             query = query.where("lead_assigned_to", "==", filterState.lead_assigned_to)
    //             // console.log(query,"RUNNING 4")
    //         }
    //         if (filterState.leadtype) {
    //             query = query.where("type", "==", filterState.leadtype)
    //         }
    //         if (filterState.from) {
    //             isFilterFromTill = true;
    //             let from = '', till = '';
    //             if (filterState.from) {
    //                 isFilterFromTill = true;
    //                 from = new Date(0);
    //                 from.setUTCSeconds(filterState?.from?.seconds);
    //                 // from.setTime(from.getTime() - 24 * 60 * 60 * 1000);
    //                 from = firebase.firestore.Timestamp.fromDate(from);
    //                 query = query.where("created", ">=", from);
    //                 setMessage("no record found")

    //             }
    //             if (filterState.till) {

    //                 isFilterFromTill = true;
    //                 till = new Date(0);
    //                 till.setUTCSeconds(filterState?.till?.seconds);
    //                 till.setTime(till.getTime() + 24 * 60 * 60 * 1000);
    //                 till = firebase.firestore.Timestamp.fromDate(till);
    //                 query = query.where("created", "<=", till);
    //                 setMessage("no record found")
    //             }
    //         }
    //         if (startTimestamp && !isFilterFromTill && filterState.name == "" && filterState.leadtype == "") {
    //             query = query.where("updated", ">=", startTimestamp)
    //         }

    //         query.onSnapshot(querySnapshot => {
    //             console.log(querySnapshot.size, "SIZE")
    //             if (querySnapshot.size) {
    //                 setLeads(querySnapshot.docs.map(doc => ({
    //                     id: doc.id,
    //                     ...doc.data(),
    //                 })));
    //             }
    //             else {
    //                 setMessage("No record found")
    //             }

    //         });


    //     }
    //     queryss();
    // }, [
    //     client,
    //     monthsBack,
    //     filterState,
    //     props.up,
    //     engagementSearch,
    // ]);

    useEffect(() => {
        // Debounce engagementSearch updates
        const handler = debounce(() => {
            setDebouncedEngagementSearch(engagementSearch);
        }, 500); // Adjust debounce time as needed

        handler();

        return () => {
            handler.cancel(); // Cleanup the debounce on unmount or dependency change
        };
    }, [engagementSearch]);

    // useEffect(() => {
    //     setLeads([]);
    //     setMessage("Loading...");
    //     const startTimestamp = firebase.firestore.Timestamp.fromMillis(startFrom);

    //     const queryss = async () => {
    //         try {
    //             let query = db.collection(`clients/${client}/leads`);
    //             let isFilterFromTill = false;

    //             if (filterState.name) {
    //                 query = query.where("saved_by", "==", filterState.name);
    //             }
    //             if (!filterState.name && filterState.lead_assigned_to) {
    //                 query = query.where("saved_by", "==", filterState.lead_assigned_to);
    //             }
    //             if (filterState.name && filterState.lead_assigned_to && filterState.name !== filterState.lead_assigned_to) {
    //                 query = query.where("lead_assigned_to", "==", filterState.lead_assigned_to);
    //             }
    //             if (filterState.leadtype) {
    //                 query = query.where("type", "==", filterState.leadtype);
    //             }
    //             if (filterState.from) {
    //                 isFilterFromTill = true;
    //                 let from = '', till = '';
    //                 if (filterState.from) {
    //                     from = new Date(0);
    //                     from.setUTCSeconds(filterState?.from?.seconds);
    //                     from = firebase.firestore.Timestamp.fromDate(from);
    //                     query = query.where("created", ">=", from);
    //                 }
    //                 if (filterState.till) {
    //                     till = new Date(0);
    //                     till.setUTCSeconds(filterState?.till?.seconds);
    //                     till.setTime(till.getTime() + 24 * 60 * 60 * 1000);
    //                     till = firebase.firestore.Timestamp.fromDate(till);
    //                     query = query.where("created", "<=", till);
    //                 }
    //             }
    //             if (startTimestamp && !isFilterFromTill && filterState.name === "" && filterState.leadtype === "") {
    //                 query = query.where("updated", ">=", startTimestamp);
    //             }

    //             query.onSnapshot(async (querySnapshot) => {
    //                 if (querySnapshot.size) {
    //                     let leadsData = [];
    //                     for (const doc of querySnapshot.docs) {
    //                         const leadData = { id: doc.id, ...doc.data() };
    //                         console.log(leadData, "==asmamcmasmcssacksa");

    //                         // Check engagements sub-collection if engagementSearch is provided
    //                         if (debouncedEngagementSearch) {
    //                             const engagementsRef = db.collection(`clients/${client}/leads/${doc.id}/engagements`);
    //                             const engagementsSnapshot = await engagementsRef
    //                                 .where("message", ">=", debouncedEngagementSearch) // Or use "==" for exact match
    //                                 .where("message", "<", debouncedEngagementSearch + "\uf8ff")
    //                                 .get();

    //                             if (!engagementsSnapshot.empty) {
    //                                 leadData.engagements = engagementsSnapshot.docs.map((engDoc) => engDoc.data());
    //                                 leadsData.push(leadData);
    //                             }
    //                         } else {
    //                             leadsData.push(leadData); // Add lead data without filtering engagements
    //                         }
    //                     }

    //                     console.log(leadsData, "==asmamcmasmcssacksa222");
    //                     setLeads(leadsData);
    //                     setMessage("No record found");
    //                 } else {
    //                     setMessage("No record found");
    //                 }
    //             });
    //         } catch (error) {
    //             console.error("Error fetching leads with engagements:", error);
    //             setMessage("Error occurred while loading data.");
    //         }
    //     };

    //     queryss();
    // }, [
    //     client,
    //     monthsBack,
    //     filterState,
    //     props.up,
    //     // engagementSearch,
    //     debouncedEngagementSearch,
    // ]);


    useEffect(() => {
        setLeads([]);
        setMessage("Loading...");
        const startTimestamp = firebase.firestore.Timestamp.fromMillis(startFrom);

        console.log(filterState.name, filterState.lead_assigned_to, "=makcasmcmkascasmsakcsa");

        // const queryLeadsWithEngagements = async () => {
        //     try {
        //         let query = db.collection(`clients/${client}/leads`);
        //         let isFilterFromTill = false;

        //         // Apply filters to the leads query
        //         if (filterState.name) {
        //             query = query.where("saved_by", "==", filterState.name);
        //         }
        //         if (!filterState.name && filterState.lead_assigned_to) {
        //             // query = query.where("saved_by", "==", filterState.lead_assigned_to);
        //             query = query.where("lead_assigned_to", "==", filterState.lead_assigned_to);
        //         }
        //         if (filterState.name && filterState.lead_assigned_to && filterState.name !== filterState.lead_assigned_to) {
        //             query = query.where("lead_assigned_to", "==", filterState.lead_assigned_to);
        //         }
        //         if (filterState.leadtype) {
        //             query = query.where("type", "==", filterState.leadtype);
        //         }
        //         if (filterState.from) {
        //             isFilterFromTill = true;
        //             let from = '', till = '';
        //             if (filterState.from) {
        //                 from = new Date(0);
        //                 from.setUTCSeconds(filterState?.from?.seconds);
        //                 from = firebase.firestore.Timestamp.fromDate(from);
        //                 query = query.where("created", ">=", from);
        //             }
        //             if (filterState.till) {
        //                 till = new Date(0);
        //                 till.setUTCSeconds(filterState?.till?.seconds);
        //                 till.setTime(till.getTime() + 24 * 60 * 60 * 1000);
        //                 till = firebase.firestore.Timestamp.fromDate(till);
        //                 query = query.where("created", "<=", till);
        //             }
        //         }
        //         if (startTimestamp && !isFilterFromTill && filterState.name === "" && filterState.leadtype === "") {
        //             query = query.where("updated", ">=", startTimestamp);
        //         }

        //         // Fetch leads
        //         const querySnapshot = await query.get();
        //         if (!querySnapshot.empty) {
        //             const leadsData = [];
        //             const leadPromises = querySnapshot.docs.map(async (doc) => {
        //                 const leadData = { id: doc.id, ...doc.data() };

        //                 // Fetch all engagements for the lead
        //                 const engagementsRef = db.collection(`clients/${client}/leads/${doc.id}/engagements`);
        //                 const engagementsSnapshot = await engagementsRef.get();

        //                 const searchStrInLowerCase = debouncedEngagementSearch?.toLowerCase();
        //                 // Filter engagements locally if `debouncedEngagementSearch` is provided
        //                 if (!engagementsSnapshot.empty) {
        //                     const engagements = engagementsSnapshot.docs.map((engDoc) => engDoc.data());
        //                     if (debouncedEngagementSearch) {
        //                         leadData.engagements = engagements.filter((eng) =>
        //                             eng.message?.toLowerCase()?.includes(searchStrInLowerCase)
        //                         );
        //                     } else {
        //                         leadData.engagements = engagements; // Attach all engagements if no search is provided
        //                     }
        //                 }
        //                 // Add lead to the result if engagements match or no search is applied
        //                 if (!debouncedEngagementSearch || (leadData.engagements && leadData.engagements.length > 0) 
        //                     || leadData.name?.toLowerCase()?.includes(searchStrInLowerCase)
        //                     || leadData.company?.toLowerCase()?.includes(searchStrInLowerCase)
        //                     || leadData.job_title?.toLowerCase()?.includes(searchStrInLowerCase)
        //                 ) {
        //                     leadsData.push(leadData);
        //                 }
        //             });

        //             // Wait for all promises to complete
        //             await Promise.all(leadPromises);
        //             console.log(leadsData, "asjcjassbacbsabca");

        //             setLeads(leadsData);
        //             handleLeadStore(leadsData);
        //             setMessage("No record found");
        //         } else {
        //             setMessage("No record found");
        //         }
        //     } catch (error) {
        //         console.error("Error fetching leads with engagements:", error);
        //         setMessage("Error occurred while loading data.");
        //     }
        // };

        const queryLeadsWithEngagements = async () => {
            try {
                let query = db.collection(`clients/${client}/leads`);
                let isFilterFromTill = false;

                // Apply filters to the leads query
                if (filterState.name) {
                    query = query.where("saved_by", "==", filterState.name);
                }
                // if (!filterState.name && filterState.lead_assigned_to) {
                //     // query = query.where("saved_by", "==", filterState.lead_assigned_to);
                //     query = query.where("lead_assigned_to", "==", filterState.lead_assigned_to);
                // }
                // if (filterState.name && filterState.lead_assigned_to && filterState.name !== filterState.lead_assigned_to) {
                //     query = query.where("lead_assigned_to", "==", filterState.lead_assigned_to);
                // }
                if (filterState.leadtype) {
                    query = query.where("type", "==", filterState.leadtype);
                }
                if (filterState.from) {
                    isFilterFromTill = true;
                    let from = '', till = '';
                    if (filterState.from) {
                        from = new Date(0);
                        from.setUTCSeconds(filterState?.from?.seconds);
                        from = firebase.firestore.Timestamp.fromDate(from);
                        query = query.where("created", ">=", from);
                    }
                    if (filterState.till) {
                        till = new Date(0);
                        till.setUTCSeconds(filterState?.till?.seconds);
                        till.setTime(till.getTime() + 24 * 60 * 60 * 1000);
                        till = firebase.firestore.Timestamp.fromDate(till);
                        query = query.where("created", "<=", till);
                    }
                }
                if (startTimestamp && !isFilterFromTill && filterState.name === "" && filterState.leadtype === "") {
                    query = query.where("updated", ">=", startTimestamp);
                }

                // Fetch leads
                const querySnapshot = await query.get();
                if (!querySnapshot.empty) {
                    const leadsData = [];
                    const leadPromises = querySnapshot.docs.map(async (doc) => {
                        const leadData = { id: doc.id, ...doc.data() };

                        // Fetch all engagements for the lead
                        const engagementsRef = db.collection(`clients/${client}/leads/${doc.id}/engagements`);
                        const engagementsSnapshot = await engagementsRef.get();

                        const searchStrInLowerCase = debouncedEngagementSearch?.toLowerCase();
                        // Filter engagements locally if `debouncedEngagementSearch` is provided
                        if (!engagementsSnapshot.empty) {
                            const engagements = engagementsSnapshot.docs.map((engDoc) => engDoc.data());
                            if (debouncedEngagementSearch) {
                                leadData.engagements = engagements.filter((eng) =>
                                    eng.message?.toLowerCase()?.includes(searchStrInLowerCase)
                                );
                            } else {
                                leadData.engagements = engagements; // Attach all engagements if no search is provided
                            }
                        }
                        // Add lead to the result if engagements match or no search is applied
                        if (!debouncedEngagementSearch || (leadData.engagements && leadData.engagements.length > 0) 
                            || leadData.name?.toLowerCase()?.includes(searchStrInLowerCase)
                            || leadData.company?.toLowerCase()?.includes(searchStrInLowerCase)
                            || leadData.job_title?.toLowerCase()?.includes(searchStrInLowerCase)
                        ) {
                            if(filterState.lead_assigned_to){
                                console.log({ leadData, 
                                    first: (!leadData.lead_assigned_to || leadData.lead_assigned_to?.length === 0),
                                    second: leadData.saved_by == leadData.lead_assigned_to,
                                    third: leadData.lead_assigned_to === filterState.lead_assigned_to,
                                    lead_assigned_to: filterState.lead_assigned_to,
                                }, "ascnjancjsancsnacajcsn");
                                
                                if((!leadData.lead_assigned_to || leadData.lead_assigned_to?.length === 0) && leadData.saved_by == filterState.lead_assigned_to || leadData.lead_assigned_to === filterState.lead_assigned_to){
                                    leadsData.push(leadData);
                                }
                            }
                            else {
                                leadsData.push(leadData);
                            }
                        }

                    });

                    // Wait for all promises to complete
                    await Promise.all(leadPromises);
                    console.log(leadsData, "asjcjassbacbsabca");

                    setLeads(leadsData);
                    handleLeadStore(leadsData);
                    setMessage("No record found");
                } else {
                    setMessage("No record found");
                }
            } catch (error) {
                console.error("Error fetching leads with engagements:", error);
                setMessage("Error occurred while loading data.");
            }
        };
        
        queryLeadsWithEngagements();
    }, [
        client,
        monthsBack,
        filterState,
        props.up,
        debouncedEngagementSearch,
    ]);


    useLayoutEffect(() => {
        // console.log("allusers get")
        dispatch({ type: actionTypes.getUsers })
        dispatch({ type: actionTypes.getAllUsers })
    }, [])

    const handleChange = (e) => {
        const value = e.target.value;
        console.log(value, '===valuevalue', lead_types);


        setFilterState({
            ...filterState,
            [e.target.name]: value
        })
    }

    const handleDatechangeFrom = (date) => {
        const readable = moment(date).format('DD/MM/YYYY HH:mm');
        const seconds = moment(date).format('X');
        console.log(readable, "==readablereadable");

        setFilterState({
            ...filterState,
            from: {
                seconds: seconds,
                readable: readable
            }
        })

    }
    const handleDatechangeTill = (date) => {
        const readable = moment(date).format('DD/MM/YYYY HH:mm');
        const seconds = moment(date).format('X');
        setFilterState({
            ...filterState,
            till: {
                seconds: seconds,
                readable: readable
            }
        })


    }


    useEffect(() => {
        const toSort = [...leads.filter((lead, index, self) =>
            self.findIndex(l => l.id === lead.id) === index
        )]

        toSort.sort(sortDateType);

        setInOrderLeads(toSort);


    }, [leads]);


    const sortDateType = (a, b) => {
        // console.log("sortedDAta")

        /**
         * If the dates are the same, sort by the type
         */
        if (a.type > b.type) {
            return 1;
        }
        if (a.type < b.type) {
            return -1;
        }

        /**
         * Sort by the date the lead was recorded
         */
        if (a.updated && b.updated) {
            const date = b.updated.seconds - a.updated.seconds;
            if (date !== 0) {
                return date;
            }
            return 0;
        }
        return 0;
    }

    /**
     * Sort the different leads into month blocks for displaying on the front end
     */
    useEffect(() => {
        /**
         *
         */

        let leadsUI = [];
        /**
         *
         */
        inOrderLeads.forEach((lead, index) => {
            /**
             *
             */
            if (lead.updated) {
                /**
                 * Get the year and month for the given lead
                 */
                const year = moment(lead.updated.seconds, "X").format("YYYY");
                const month = moment(lead.updated.seconds, "X").format("MM");
                /**
                 * Make sure there is a year element available
                 */
                if (!leadsUI[`${year}`]) {
                    leadsUI[`${year}`] = [];
                    for (let i = 1; i <= 12; i++) {
                        leadsUI[`${year}`][i] = [];
                    }
                }
                /**
                 * Then push the lead to that month
                 */
                leadsUI[`${year}`][`${parseInt(month, 10)}`].push(lead);
            } else {
                /**
                 *
                 */
                if (!leadsUI["Uncategorized"]) {
                    leadsUI["Uncategorized"] = [];
                }
                /**
                 *
                 */
                leadsUI["Uncategorized"].push(lead);
            }
        });
        /**
         * 
         */
        // console.log(leadsUI, "====leadsUIleadsUI");

        const newLeads = leadsUI.filter((ls) => {
            delete ls[0];
            return ls;
        })
        // console.log(newLeads, "====leadsUIleadsUI22");
        setSortedLeads(newLeads);
    }, [inOrderLeads]);


    // const fetchEngagements = (client, leadId) => {
    //     return new Promise((resolve, reject) => {
    //         db.collection(`clients/${client}/leads/${leadId}/engagements`).onSnapshot((engagementsSnap) => {
    //             let engagements = [];
    //             try {
    //                 engagementsSnap.docChanges().forEach((change) => {
    //                     if (change.type === 'added') {
    //                         engagements.push({ id: change.doc.id, ...change.doc.data() });
    //                     }
    //                     if (change.type === 'modified') {
    //                         engagements = engagements.filter(e => e.id !== change.doc.id);
    //                         engagements.push({ id: change.doc.id, ...change.doc.data() });
    //                     }
    //                     if (change.type === 'removed') {
    //                         engagements = engagements.filter(e => e.id !== change.doc.id);
    //                     }
    //                 });
    //                 engagements.sort((a, b) => new Date(a.created.seconds) - new Date(b.created.seconds));
    //                 resolve(engagements);
    //             } catch (error) {
    //                 reject(error);
    //             }
    //         });
    //     });
    // };


    // const changeCSVdata = async (leadsData) => {
    //     // console.log(leadsData, "====leadsData====");
    //     let newLeads = [];
    //     for (const [index, lead] of leadsData.entries()) {
    //         let usrMatch;
    //         for (let j = 0; j < users.length; j++) {
    //             if (users[j].id === lead.saved_by) {
    //                 usrMatch = users[j];
    //                 break;
    //             }
    //         }
    //         if (usrMatch !== undefined) {
    //             // Wait for engagements to be fetched
    //             const engagements = await fetchEngagements(client, lead.id);
    //             // console.log(engagements, "Fetched engagements for lead:", lead);

    //             let newLead = { ...lead };
    //             newLead.saved_by = usrMatch.name;
    //             delete newLead.init_engagement;
    //             newLead.id = index + 1;

    //             if (newLead.hasOwnProperty('created')) {
    //                 let d = new Date(0);
    //                 d.setUTCSeconds(newLead.created);
    //                 newLead.created = d;
    //             }
    //             if (newLead.hasOwnProperty('updated')) {
    //                 let d = new Date(0);
    //                 d.setUTCSeconds(newLead.updated);
    //                 newLead.updated = d;
    //             }

    //             // Add engagement messages to the lead object dynamically
    //             const engm = {}
    //             engagements.forEach((engagement, i) => {
    //                 engm[`init_message_${i + 1}`] = engagement.message;
    //             });


    //             newLeads.push({
    //                 id: newLead.id,
    //                 type: newLead.type,
    //                 name: newLead.name,
    //                 company: newLead.company,
    //                 contact_email: newLead.contact_email,
    //                 contact_number: newLead.contact_number,
    //                 website: newLead.website,
    //                 linkedin: newLead.linkedin,
    //                 where: newLead.where,
    //                 saved_by: newLead.saved_by,
    //                 assigned_to: '',
    //                 job_title: newLead.job_title,
    //                 created: newLead.created,
    //                 updated: newLead.updated,
    //                 ...engm,
    //             });
    //         }
    //     }
    //     return newLeads;
    // };


    // const changeCSVdata = (leadsData) => {
    //     console.log(leadsData, "====leadsDataleadsData"); 
    //     let newLeads = []
    //     leadsData.forEach(async (lead, index) => {
    //         let usrMatch;
    //         for (let j = 0; j < users.length; j++) {
    //             if (users[j].id === lead.saved_by) {
    //                 usrMatch = users[j];
    //                 break;
    //             }
    //         }
    //         if (usrMatch !== undefined) {
    //             const engagements = await fetchEngagements(client, lead.id);
    //             console.log(engagements, "asvavnvjnjanvnasnv:davna");

    //             let newLead = { ...lead };
    //             newLead.saved_by = usrMatch.name;
    //             // newLead.init_engagement = '';
    //             delete newLead.init_engagement
    //             newLead.id = index + 1;
    //             if (newLead.hasOwnProperty('created')) {
    //                 let d = new Date(0);
    //                 d.setUTCSeconds(newLead.created);
    //                 newLead.created = d;
    //             }
    //             if (newLead.hasOwnProperty('updated')) {
    //                 let d = new Date(0);
    //                 d.setUTCSeconds(newLead.updated);
    //                 newLead.updated = d;
    //             }
    //             newLeads.push(newLead);
    //             newLeads.push({
    //                 id: newLead.id,
    //                 type: newLead.type,
    //                 name: newLead.name,
    //                 company: newLead.company,
    //                 contact_email: newLead.contact_email,
    //                 contact_number: newLead.contact_number,
    //                 website: newLead.website,
    //                 linkedin: newLead.linkedin,
    //                 where: newLead.where,
    //                 saved_by: newLead.saved_by,
    //                 assigned_to: '',
    //                 job_title: newLead.job_title,
    //                 created: newLead.created,
    //                 updated: newLead.updated,
    //                 init_message: newLead.init_message,
    //             });
    //         }
    //     });
    //     return newLeads;
    // }

    const getFIleNameOfCSV = (name, filterState = {}) => {
        if (filterState.name?.length > 0) {
            const user = users?.find(u => u.id == filterState.name);
            name += `-${user?.name}`;
        }
        if (filterState.lead_assigned_to?.length > 0) {
            const user = users?.find(u => u.id == filterState.lead_assigned_to);
            name += `-${user?.name}`;
        }
        if (filterState.leadtype?.length > 0) {
            name += `-${filterState.leadtype.substring(2)}`;
        }
        if (filterState.from?.readable) {
            name += `-${filterState.from?.readable.replace("00:00", "")}`;
        }
        if (filterState.till?.readable) {
            name += `-${filterState.till?.readable.replace("00:00", "")}`;
        }

        return name;
    }

    // console.log(leads,"[[aaciscaj");


    return (
        <>
            {/* View lead modal */}
            <ViewLead
                active={showingLead}
                client={client}
                lead={lead}
                users={users}
                dismiss={() => setShowingLead(false)}
            />
            {/* View SDR modal */}
            <ViewSDR
                active={showingSDR}
                client={client}
                lead={lead}
                dismiss={() => setShowingSDR(false)} />

            <div className="parentclass">
                <div style={{ display: 'flex', }} >

                    <div style={{ margin: "6px" }} >
                        <select name="name" onChange={handleChange}
                            value={filterState.name}

                            style={{ padding: "8px 12px", color: "white", backgroundColor: "#3e3e3e", border: "solid 2px #3e3e3e", width: '200px' }}
                        // value={"User"}
                        >
                            <option value="" >USER</option>
                            {
                                Array.isArray(users)
                                    ?
                                    (<>

                                        {
                                            users?.map((usr, ind) => {
                                                return (
                                                    <option
                                                        key={ind}
                                                        style={{ color: "white", backgroundColor: "#3e3e3e" }}
                                                        value={usr.id} >{usr.name}</option>
                                                )
                                            })
                                        }
                                    </>)
                                    :
                                    (<>

                                    </>)

                            }

                        </select>
                    </div>
                    <div style={{ margin: "6px" }} >
                        <select name="lead_assigned_to" onChange={handleChange}
                            value={filterState.lead_assigned_to}

                            style={{ padding: "8px 12px", color: "white", backgroundColor: "#3e3e3e", border: "solid 2px #3e3e3e", width: '200px' }}
                        // value={"User"}
                        >
                            <option value="" >Lead Assign To</option>
                            {
                                Array.isArray(users)
                                    ?
                                    (<>

                                        {
                                            users?.map((usr, ind) => {
                                                return (
                                                    <option
                                                        key={ind}
                                                        style={{ color: "white", backgroundColor: "#3e3e3e" }}
                                                        value={usr.id} >{usr.name}</option>
                                                )
                                            })
                                        }
                                    </>)
                                    :
                                    (<>

                                    </>)

                            }

                        </select>
                    </div>

                    <div style={{ margin: "5px" }}>

                        <select name="leadtype" onChange={handleChange}
                            value={filterState.leadtype}
                            style={{ padding: "8px", color: "white", backgroundColor: "#3e3e3e", border: "solid 2px #3e3e3e" }}

                        //  value={filterState.leadtype} 
                        >
                            <option value="" >Lead Type</option>
                            {
                                lead_types ?
                                    (<>
                                        {
                                            Object.keys(lead_types).map((val, key) => {
                                                return (
                                                    <option
                                                        key={key}
                                                        style={{ color: "white", backgroundColor: "#3e3e3e" }}
                                                        value={lead_types[val]} >{val}</option>
                                                )
                                            })
                                        }
                                    </>) : (<></>)
                            }
                        </select>
                    </div>

                    <div style={{ margin: "5px" }}>

                        {/* <label>From</label> */}
                        <Datetime
                            value={filterState.from?.readable}
                            onChange={handleDatechangeFrom}
                            name="from"
                            inputProps={{ placeholder: 'From' }}

                        />
                    </div>
                    <div style={{ margin: "5px" }}>

                        {/* <label>till</label> */}
                        {/* <input type="date" />
                         */}

                        <Datetime
                            value={filterState.till?.readable}
                            onChange={handleDatechangeTill}
                            name="till"
                            inputProps={{ placeholder: 'Till' }}

                        />
                    </div>
                    <div style={{ display: 'flex', gap: '6px', margin: "6px" }} >
                        {/* <button
                            className="filterBtn" 
                            style={{ cursor: 'pointer' }}
                            // style={{ color: "white", backgroundColor: "#3e3e3e", padding: "11px 60px", cursor: "pointer", }}
                            onClick={filter} >Filter</button> */}

                        <button
                            className="filterBtn"
                            style={{ marginLeft: '5px', cursor: 'pointer', height: '43px' }}
                            // style={{ color: "white", backgroundColor: "#3e3e3e", padding: "11px 60px", marginLeft: '10px', cursor: "pointer" }}
                            onClick={reset} >Reset</button>


                        {/* <CSVLink filename={getFIleNameOfCSV("All", filterState)} data={changeCSVdata(leads)} >
                            <button
                                className="filterBtn"
                                style={{ marginLeft: '5px', cursor: 'pointer' }}
                            >Download</button>
                        </CSVLink> */}

                        <GenerateCSV client={client} data={leads} filename={getFIleNameOfCSV(clientName, filterState)}>
                            <button className="filterBtn" style={{ marginLeft: '5px', cursor: 'pointer' }}>Download</button>
                        </GenerateCSV>
                    </div>
                </div>
            </div>

            <div>
                {/* {console.log(sortedLeads, "SORTED LEAEDS")} */}
                {
                    sortedLeads?.length > 0 ?
                        (
                            <div>
                                {sortedLeads?.reverse().map((months, year) => {
                                    return (
                                        <div key={year}>
                                            {months?.slice(0)?.reverse()?.map((leads, month) => {
                                                let fname = "";
                                                if (leads[year]?.created?.seconds === undefined) {
                                                    fname = moment.unix(leads[month]?.updated?.seconds).format("MMMM YYYY")
                                                } else {
                                                    fname = moment.unix(leads[year]?.updated?.seconds).format("MMMM YYYY")
                                                }
                                                
                                                if(!leads[year]?.updated || !leads[year]?.created){
                                                    fname = moment.unix(leads?.[0]?.updated || leads?.[0]?.created).format("MMMM YYYY");
                                                }
                                                console.log({ first: leads[year], seconds: leads[month], fname, leads }, "jnscjasncascknasnc")

                                                {/* const dat = changeCSVdata(leads, false) */ }
                                                return (
                                                    <div key={`${year}${month}`}>
                                                        {leads?.length > 0 &&


                                                            (
                                                                <>
                                                                    <div key={`${year}${month}`} className="lead-list-block">
                                                                        <div className="lead-list-header" >
                                                                            <p className="header-month">
                                                                                {fname}
                                                                                {/* {moment.unix(leads[month]?.created?.seconds).format("MMMM YYYY")} */}
                                                                            </p>
                                                                            <hr />
                                                                            <LeadsCount data={leads} />
                                                                            {/* <CSVLink
                                                                                // className="filterBtn"
                                                                                className="downloadbtn"

                                                                                // style={{ cursor: 'pointer' , color:"red"}}
                                                                                // filename={fname}
                                                                                filename={getFIleNameOfCSV(fname, filterState)}
                                                                                data={dat}
                                                                            >
                                                                                <FontAwesomeIcon style={{ marginLeft: "20px" }} icon={faDownload} className="fa-lg" />
                                                                            </CSVLink> */}
                                                                            <GenerateCSV client={client} data={leads} filename={getFIleNameOfCSV(clientName + "-" + fname, filterState)} className="downloadbtn">
                                                                                <FontAwesomeIcon style={{ marginLeft: "20px" }} icon={faDownload} className="fa-lg" />
                                                                            </GenerateCSV>
                                                                        </div>
                                                                        <div>
                                                                            <p className="header-month">
                                                                                {/* {moment(12 - month, "MM").format("MMMM")} {moment().subtract(year, "years").format("YYYY")} */}
                                                                            </p>
                                                                            <hr />

                                                                        </div>
                                                                        {leads?.map((lead, index) => (
                                                                            <>
                                                                                <Lead
                                                                                    key={lead.id}
                                                                                    id={lead.id}
                                                                                    sNo={index + 1}
                                                                                    data={lead}
                                                                                    edit={(lead) => {
                                                                                        console.log(lead, "=ascmaacnas:leaddddd", client)
                                                                                        setLead(lead);
                                                                                        setShowingLead(true);
                                                                                    }}
                                                                                    viewSDR={(lead) => {
                                                                                        setLead(lead);
                                                                                        setShowingSDR(true);
                                                                                    }} />
                                                                            </>
                                                                        ))}
                                                                    </div>
                                                                </>)


                                                        }

                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )
                                })}
                            </div>

                        )
                        :
                        (<>
                            <div style={{ textAlign: 'center' }} >
                                <p style={{ fontSize: "20px", fontWeight: 800 }} >{message}</p>
                            </div>
                        </>)
                }

                {filterState.name || filterState.from || filterState.till || filterState.leadtype ? (<></>) : (
                    <>
                        <Button
                            class="load-more-months"
                            onClick={() => setMonthsBack(monthsBack + 1)}>
                            Load another month
                        </Button>
                    </>
                )}

            </div>
        </>
    );
}

export default Leads;