import React, { useEffect, useState } from "react";
import { db } from "../../utils/firebase";
// import moment from "moment";
import "./appointments.scss";

/**
 * UI components
 */
import Client from "./client/client";
import Select from "../../components/ui/selects/select";

/**
 * Functional component to return the appointments leaderboard
 */
export default function Appointments() {
    const moment = require('moment');
    moment.locale('en-au')
    const [clients, setClients] = useState([]);
    const [sortedClients, setSortedClients] = useState([]);
    const [monthName, setMonthName] = useState(moment().format("MMMM"));
    const [monthScope, setMonthScope] = useState(moment().format("MM"));
    const [yearScope, setYearScope] = useState(moment().year());
    // const [yearList, setyearList] = useState([])

    /**
     * When the month name is updated
     */
    useEffect(() => {
        /**
         * Format it into the numerical version
         */
        const monthNumber = moment(monthName, "MMMM").format("MM");
        /**
         * And then set it into the state
         */
        setMonthScope(monthNumber);
        years(100, moment().year())

    }, [monthName]);

    const [prevYears, setPrevYears] = useState([])
    function years(count, startYear) {
        const yearList = [];
        const year = startYear || new Date().getFullYear();
        for (let i = 0; i < count; i += 1) {
            yearList.push(Number(year) - i)
        }
        yearList.sort((a, b) => a - b)
        // return yearList.reverse()
        setPrevYears(yearList.reverse())
    }

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Setup a listener on the clients collection to grab them all that are marked as 
         * showing on the tracker
         */
        const unsubscribe = db.collection("clients")
            .where("tracker", "==", true)
            .onSnapshot((clientsSnap) => {
                /**
                 * Loop through the changes from the snapshot
                 */
                clientsSnap.docChanges().forEach(async (change) => {
                    /**
                     * Filter the action into the relevant update method
                     */
                    if (change.type === "added") {
                        /**
                         * Push the client into the state
                         */
                        setClients((clients) => [...clients, {
                            id: change.doc.id,
                            ...change.doc.data(),
                        }]);
                    } else if (change.type === "modified") {
                        /**
                         * Update the current client in the state
                         */
                        setClients((clients) => {
                            let currentClients = [...clients];
                            for (let i in clients) {
                                if (clients[i].id === change.doc.id) {
                                    currentClients[i] = {
                                        ...currentClients[i],
                                        id: change.doc.id,
                                    };
                                    break;
                                }
                            }
                            return currentClients;
                        });
                    } else if (change.type === "removed") {
                        /**
                         * Remove the client from the array
                         */
                        setClients((clients) => clients.filter((clientElement) => clientElement.id !== change.doc.id));
                    }
                });
            });
        /**
         * Remove the listener on unload
         */
        return () => unsubscribe();
    }, []);

    /**
     * Called from the client components, this updates the KPI progress for the client 
     * in the state for sorting
     */
    const updateKPIProgress = (clientID, progress) => {
        /**
         * Update the current client in the state
         */
        setClients((clients) => {
            let currentClients = [...clients];
            for (let i in clients) {
                if (clients[i].id === clientID) {
                    currentClients[i] = {
                        id: clientID,
                        progress: progress || 0,
                    };
                    break;
                }
            }
            return currentClients;
        });
    }

    /**
     * When the clients array is updated
     */
    useEffect(() => {
        /**
         * Get a copy
         */
        const copiedClients = [...clients];
        console.log(copiedClients, "==copiedClients111");
        
        /**
         * Sort this new copy
        */
       copiedClients.sort((a, b) => {
        let aProgress = a.progress || 0;
        let bProgress = b.progress || 0;
           if (aProgress < bProgress) {
               return 1;
            }
            if (aProgress > bProgress) {
                return -1;
            }
            return 0;
        });
        console.log(copiedClients, "==copiedClients222");
        /**
         * Set the sorted copy into the state
         */
        setSortedClients(copiedClients);
    }, [clients]);
    // console.log(yearScope, "SCOPE OF THIS ")


    return (
        <div id="appointments-window">
            <div className="appointments-title">
                <h6>Appointments Tracker</h6>

                <Select
                    placeholder="Choose month:"
                    options={{
                        "1": "January",
                        "2": "February",
                        "3": "March",
                        "4": "April",
                        "5": "May",
                        "6": "June",
                        "7": "July",
                        "8": "August",
                        "9": "September",
                        "10": "October",
                        "11": "November",
                        "12": "December",
                    }}
                    value={monthName}
                    onSelect={(option) => setMonthName(option.value)} />

                <Select
                    placeholder="year:"

                    // options={{
                    //     "1": "2023",
                    //     "2": "2022",
                    //     "3": "2021",
                    //     "4": "2020",
                    //     "5": "2019",
                    //     "6": "2018",
                    //     "7": "2017",
                    // }}
                    options={prevYears.map((val) => val)}
                    value={yearScope}
                    onSelect={(option) => setYearScope(option.value)}
                />
            </div>

            {/* Show the sorted clients if there are any */}
            {(sortedClients.length > 0) &&
                <>
                    {sortedClients.map((client) => (
                        <Client
                            key={client.id}
                            id={client.id}
                            scope={`${monthScope}-${yearScope}`}
                            update={(clientID, progress) => updateKPIProgress(clientID, progress)} />
                    ))}
                </>
            }

            {/* Otherwise just print the default list of clients */}
            {(sortedClients.length === 0) &&
                <>
                    {clients.map((client) => (
                        <Client
                            key={client.id}
                            id={client.id}
                            scope={`${monthScope}-${yearScope}`}
                            update={(clientID, progress) => updateKPIProgress(clientID, progress)} />
                    ))}
                </>
            }
        </div>
    );
}